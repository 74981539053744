let utils = {
  toLog10: function log10(x) {
    return Math.log(x) / Math.LN10;
  },
  toDecibel: function value2decibel(x) {
    return 20 * Math.log10(x);
  },
  toValue: function decibel2value(x) {
    return Math.pow(10, (x / 20));
  },
  getChartObject: function (data, label){
    return {
      label: label,
      fill: false,
      backgroundColor: 'rgba(75,192,192,0.4)',
      pointBorderColor: this.getLineColor(label),
      pointBackgroundColor: 'transparent',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      // pointRadius: 1,
      pointHitRadius: 10,
      borderColor: this.getLineColor(label),
      borderWidth: 1,
      // pointBorderColor: ['#000', '#00bcd6', '#d300d6'],
      pointRadius: 10,
      tension: 0,
      showLine: true,
      pointStyle: this.getStyle(label),
      spanGaps: true,
      data: data
    }
  },

  getStyle: function getStyle (label){
    let symbol = ''
    if (label === 'Active') {
      return symbol = 'circle'
    }
    if (label === 'Left'){
      symbol = 'triangle'
    }else{
      symbol = 'rect'
    }
    return symbol;
  },

  getLineColor: function getLineColor(label) {
    let color = ''
    if (label === 'Active') {
      return color = 'green'
    }
    if (label === 'Left') {
      color = 'blue'
    } else {
      color = 'red'
    }
    return color;
  }
};

export default utils;