import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import Chart from '../../components/chart';
import { decibelData, frequencyData, LEFT_CHANNEL, RIGHT_CHANNEL } from '../../constant/chart';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import './hearing-test.css';
import { bindActionCreators } from 'redux';
import * as chartActions from '../../actions/chart-actions';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ChartJ from '../../components/chart-1';

import left_icon from '../../assets/left.png';
import right_icon from '../../assets/right.png';
import left_active_icon from '../../assets/left-active.png';
import right_active_icon from '../../assets/right-active.png';

import left_triangle_icon from '../../assets/left-triangle-icon.png';
import right_react_icon from '../../assets/right-react-icon.png';
import left_triangle_active_icon from '../../assets/left-triangle-black-icon.png';
import right_react_active_icon from '../../assets/right-react-black-icon.png';
import sound_icon from '../../assets/sound.png';

import QueryString from 'query-string'

const MAIN_VOLUME = 0.05;
const INITIAL_VOLUME = 0.5;

const MIN_VOLUME = 0.05;
const MAX_VOLUME = 1;

const MAX_DB = 0;
const MIN_DB = -56;
const NUM_OF_FRAMES = 8;

const { innerWidth: width1, innerHeight: height1 } = window;

var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
var mainVolume = audioCtx.createGain();
mainVolume.gain.value = MAIN_VOLUME;
mainVolume.connect(audioCtx.destination);

var changedDB = '';
var currDB = MIN_DB;

var mydata = [];
var my_start_button = "Start Button";
var my_heard_it = "I HEARD IT";
var my_show = "Show";
var my_index;

class HearingTest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeCorordinate: {
        id: 'left',
        x: null,
        y: null
      },
      selectedLeftCordinates: [],
      selectedRightCordinates: [],
      isLeftChannelActive: true,
      selectedAudioDeviceIsHeadphone: true,
      testStarted: false,
      displayShowButton: false,
      displayHeardButton: false,
      heardButtonWait: true,
      testFinished: false
    };
    this.currentDecibaleIndex = 0;
    this.currentFrequencyIndex = 0;

    const params = QueryString.parse(props.location.search);
    my_index = params.p;

    this.getData(params.p);
    // this.audioCtx = new AudioContext;
    // this.panNode = audioCtx.createStereoPanner();
    // this.panNode.pan.value = -1;
  }

  getData(json_id) {
    fetch('https://hearing-test.goaxil.com/json.php'
//    fetch('https://zhr-reports.com/docusign/json.php'
      , {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        crossDomain: true,
        mode: "cors",
        method: "GET"
      }
    )
      .then(function (response) {
        console.log(response)
        return response.json();
      })
      .then(myJson => {
        myJson.map((data, key) => {

          if (json_id == undefined && data.id == 1) {
            if(data.start_button != "")            
            my_start_button = data.start_button;

            if(data.heard_it != "")  
            my_heard_it = data.heard_it;

            if(data.show != "")  
            my_show = data.show;
          }

          if (data.id == json_id) {
            if(data.start_button != "")            
            my_start_button = data.start_button;

            if(data.heard_it != "")  
            my_heard_it = data.heard_it;

            if(data.show != "")  
            my_show = data.show;
          }


          this.setState({
          });
        });
      });
  }

  // Call this function when start test button will be clicked 
  optimisedStartTest = () => {
    this.setState({
      testStarted: !this.state.testStarted,
      displayHeardButton: !this.state.displayHeardButton
    })
    let intervelId = setInterval(() => {
      if (this.currentDecibaleIndex === decibelData.length - 1) {
        this.addSelectedCordinate(this.state.activeCorordinate)
        this.currentDecibaleIndex = 0;
        this.currentFrequencyIndex++
        if ((this.currentFrequencyIndex === frequencyData.length)) {
          this.currentDecibaleIndex = decibelData.length - 1;
          this.currentFrequencyIndex = frequencyData.length - 1;
        }
      }
      if ((!this.state.isLeftChannelActive) && (this.currentDecibaleIndex === decibelData.length - 1 && this.currentFrequencyIndex === frequencyData.length - 1)) {
        this.setState(prevState => {
          return {
            testFinished: !prevState.testFinished
          }
        })
      }
      if (this.currentDecibaleIndex === decibelData.length - 1 && this.currentFrequencyIndex === frequencyData.length - 1 && this.state.testFinished) {
        this.props.selectedLeftCordinates(this.state.selectedLeftCordinates);
        this.props.selectedRightCordinates(this.state.selectedRightCordinates);
        clearInterval(intervelId);
        this.setActiveCordinate({
          id: 'left',
          x: null,
          y: null
        });
        this.setState({
          displayShowButton: !this.state.displayShowButton
        });
        return false;
      }
      if (this.currentDecibaleIndex === decibelData.length - 1 && this.currentFrequencyIndex === frequencyData.length - 1) {
        this.handleSelectedChannel()
        this.currentDecibaleIndex = 0;
        this.currentFrequencyIndex = 0
      }

      if (this.currentDecibaleIndex === 0) {
        currDB = MIN_DB
      } else {
        changedDB = currDB + 3
        currDB = changedDB
      }


      this.currentDecibaleIndex = ++this.currentDecibaleIndex % decibelData.length;
      this.currentFrequencyIndex = this.currentDecibaleIndex === 0 ? ++this.currentFrequencyIndex % frequencyData.length : this.currentFrequencyIndex;
      this.plotSound(this.currentFrequencyIndex, this.currentDecibaleIndex, currDB)
      this.setActiveCordinate({
        id: this.state.isLeftChannelActive ? 'left' : 'right',
        x: frequencyData[this.currentFrequencyIndex],
        y: decibelData[this.currentDecibaleIndex]
      });
    }, 1500);
  }

  plotSound = (freqIndex, dbIndex, prevDB) => {
    // let osc = this.audioCtx.createOscillator();
    // let gain = this.audioCtx.createGain();
    // // let panNode = this.audioCtx.createStereoPanner();
    // // panNode.pan.value = -1;
    // osc.frequency.value = frequencyData[freqIndex];
    // gain.gain.value = (dbIndex + 1) / decibelData.length; // Converting 0 - 1
    // osc.connect(gain);
    // gain.connect(this.panNode);
    // this.panNode.connect(this.audioCtx.destination);
    // osc.start();
    // osc.stop(this.audioCtx.currentTime + 0.5);
    var pan = -1;
    // ====NEW CODE
    var findVolume = Math.pow(10, ((prevDB) / 20));
    console.log("vol", findVolume)
    this.frequency = frequencyData[freqIndex];
    this.volume = audioCtx.createGain();
    // this.volume.gain.value = MIN_VOLUME
    this.volume.connect(mainVolume);
    if (audioCtx.createStereoPanner) {
      this.panNode = audioCtx.createStereoPanner();
      this.panNode.connect(this.volume);
      if (this.state.isLeftChannelActive) {
        this.panNode.pan.value = -1;
      } else {
        this.panNode.pan.value = 1;
      }
    }
    else {
      this.panNode = audioCtx.createPanner();
      this.panNode.panningModel = 'equalpower';
      this.panNode.connect(this.volume);
      if (this.state.isLeftChannelActive) {
        this.panNode.setPosition(-1, 0, 1 - Math.abs(-1));
      } else {
        this.panNode.setPosition(1, 0, 1 - Math.abs(1));
      }
    }

    // if (this.frequency > 2000) {
    //   this.volume.gain.value = ((dbIndex + 1) / decibelData.length);
    // }
    this.volume.gain.value = findVolume;
    // this.volume.gain.setValueAtTime((dbIndex + 1) / decibelData.length / 35, audioCtx.currentTime)
    console.log("gain", this.volume.gain.value)
    this.oscillator = audioCtx.createOscillator();
    this.oscillator.type = 'sine';
    this.oscillator.frequency.setValueAtTime(frequencyData[freqIndex], audioCtx.currentTime);
    this.oscillator.connect(this.panNode);
    this.oscillator.start();

    this.oscillator.stop(audioCtx.currentTime + 0.5);
  }

  addSelectedCordinate = (activeCordinate) => {
    this.setState(prevState => {
      if (this.state.isLeftChannelActive) {
        return {
          selectedLeftCordinates: [...prevState.selectedLeftCordinates, activeCordinate],
        }
      } else {
        return {
          selectedRightCordinates: [...prevState.selectedRightCordinates, activeCordinate],
        }
      }
    })
  }

  handleSelectedChannel = () => {
    this.setState(prevState => {
      return {
        isLeftChannelActive: !prevState.isLeftChannelActive
      }
    }, () => {
      let { isLeftChannelActive } = this.state;
      if (audioCtx.createStereoPanner) {
        isLeftChannelActive ? this.panNode.pan.value = 1 : this.panNode.pan.value = -1;
      } else {
        if (this.state.isLeftChannelActive) {
          this.panNode.setPosition(-1, 0, 1 - Math.abs(-1));
        } else {
          this.panNode.setPosition(1, 0, 1 - Math.abs(1));
        }
      }
    })
  }

  setActiveCordinate = (valueToSet) => {
    this.setState({
      activeCorordinate: valueToSet
    });
  }

  handleHeardButtonClick = () => {
    if (this.state.activeCorordinate.x === frequencyData[this.currentFrequencyIndex]) {
      this.addSelectedCordinate(this.state.activeCorordinate)
      this.currentDecibaleIndex = 0;
      this.currentFrequencyIndex++
      if ((this.currentFrequencyIndex === frequencyData.length)) {
        this.currentDecibaleIndex = decibelData.length - 1;
        this.currentFrequencyIndex = frequencyData.length - 1;
      }
    }

  }

  render() {
    const { activeCorordinate, selectedLeftCordinates, selectedRightCordinates } = this.state;
    return (
      <>
        <div className="outer-box" style={{ height: "590px" }}>
          <div className="outer-box-border" style={{ height: "588px" }}>
            <ChartJ activeCordinate={activeCorordinate}
              selectedLeftCordinates={selectedLeftCordinates}
              selectedRightCordinates={selectedRightCordinates} />
            <div className="audio-main-control">
              <div className="audio-output-selector" style={{ top: "0px" }}>
                <div><Avatar style={{ width: "55px", height: "55px", marginRight: '35px', backgroundColor: "#ffffff" }}><img src={this.state.testStarted ? left_icon : left_active_icon} style={{ width: "54%" }} /></Avatar></div>
                <div><Avatar style={{ width: "55px", height: "55px", backgroundColor: "#ffffff" }}><img src={this.state.isLeftChannelActive ? right_active_icon : right_icon} style={{ width: "54%" }} /></Avatar></div>
              </div>
            </div>

            <span className="tray-toggle-caret" style={{ left: this.state.isLeftChannelActive ? "32px" : "122px" }}></span>

            <div className="audio-main-control2" >
              <div className="audio-output-selector" >
                <Avatar style={{ backgroundColor: "transparent", width: "30px", height: "30px", marginRight: '60px' }}><img src={this.state.testStarted ? left_triangle_icon : left_triangle_active_icon} style={{ width: "54%" }} /></Avatar>
                <Avatar style={{ width: "30px", height: "30px", backgroundColor: "transparent" }}><img src={this.state.isLeftChannelActive ? right_react_active_icon : right_react_icon} style={{ width: "54%" }} /></Avatar>
              </div>
            </div>

            <span className="left-ear-label" style={{ left: "13px", color: this.state.testStarted ? "#46b1f5" : "#000000" }}>LEFT EAR</span>
            <span className="right-ear-label" style={{ left: "98px", color: this.state.isLeftChannelActive ? "#000000" : "#ff0000" }}>RIGHT EAR</span>

            {this.state.isLeftChannelActive && this.state.testStarted && <span className="left-ear-label-active" >ACTIVE</span>}
            {!this.state.isLeftChannelActive && !this.state.displayShowButton && <span className="right-ear-label-active" >ACTIVE</span>}
            <div className="start-test-button">
              {!this.state.testStarted && <Button
                className="start-button"
                style={{ marginRight: 20 }}

                variant="contained"
                color="primary"
                onClick={this.optimisedStartTest}>
                {my_start_button}
              </Button>
              }


            </div>

            <div className="heard-button">
              <div className="sound-icon">
                {this.state.displayHeardButton && !this.state.displayShowButton &&
                  <Avatar style={{ width: "50px", height: "40px", borderRadius: "0px", backgroundColor: "transparent" }}><img src={sound_icon} style={{ width: "50px" }} /></Avatar>
                }
              </div>

              {this.state.displayHeardButton && !this.state.displayShowButton &&
                <Button
                  className="hearing-button"
                  variant="contained"
                  color="primary"
                  onClick={this.handleHeardButtonClick}
                >
                  {my_heard_it}
                </Button>
              }

              {this.state.displayShowButton &&
                <Link to={`/pdf?p=${my_index}`} style={{ textDecoration: 'none' }}>
                  <span style={{ marginLeft: '20px' }}>
                    <Button className="show-button" variant="contained" color="primary">{my_show}
                </Button>
                  </span>
                </Link>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(chartActions, dispatch)
}

export default connect(null, mapDispatchToProps)(HearingTest);
