import React, { Component } from 'react';
import Chart from '../../components/chart';
import jsPDF from "jspdf";
import Avatar from '@material-ui/core/Avatar';
import { connect } from "react-redux";
import { selectedLeftCordinates, selectedRightCordinates } from '../../actions/chart-actions';
import Button from '@material-ui/core/Button';
import * as html2canvas from 'html2canvas';
import ChartJ from '../../components/chart-1';
import './pdf-generator.css';
import left_icon from '../../assets/left.png';
import right_icon from '../../assets/right.png';

import left_triangle_icon from '../../assets/left-triangle-icon.png';
import right_react_icon from '../../assets/right-react-icon.png';

import QueryString from 'query-string'

var my_index;
var consultation_button_url = "https://www.goaxil.com/new-hearing-test-report/";
var download_result = "DOWNLOAD RESULTS";
var book_free_consultation = "Book Free Consultation";
var congrat_msg = "Congratulations! Your test is Complete!";
var want_to_know = "Want to know what all this means?";
var bottom_msg = "Please Download Your Test Results and Click \"Book Free Consultation\" to Schedule Your FREE Test Review & Consultation.";
var popup = "no";


class PdfGenerator extends Component {

  state = {
    imageData: ''
  }

  constructor(props) {
    super(props);
    const params = QueryString.parse(props.location.search);
    my_index = params.p;

    this.getData(params.p);

    this.state = {
      showPopup: false
    };
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  getData(json_id) {
    fetch('https://hearing-test.goaxil.com/json.php'
//fetch('https://zhr-reports.com/docusign/json.php'
      , {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        crossDomain: true,
        mode: "cors",
        method: "GET"
      }
    )
      .then(function (response) {
        console.log(response)
        return response.json();
      })
      .then(myJson => {

        myJson.map((data, key) => {
        
          if (json_id == "undefined" && data.id == 1) {
            if (data.url != "")
              consultation_button_url = data.url;

            if (data.download_result != "")
              download_result = data.download_result;

            if (data.book_free_consultation != "")
              book_free_consultation = data.book_free_consultation;

            if (data.congrat_msg != "")
              congrat_msg = data.congrat_msg;

            if (data.want_to_know != "")
              want_to_know = data.want_to_know;

            if (data.bottom_msg != "")
              bottom_msg = data.bottom_msg;

            if (data.popup != "")
              popup = data.popup;
          }

          if (data.id == json_id) {

            if (data.url != "")
              consultation_button_url = data.url;

            if (data.download_result != "")
              download_result = data.download_result;

            if (data.book_free_consultation != "")
              book_free_consultation = data.book_free_consultation;

            if (data.congrat_msg != "")
              congrat_msg = data.congrat_msg;

            if (data.want_to_know != "")
              want_to_know = data.want_to_know;

            if (data.bottom_msg != "")
              bottom_msg = data.bottom_msg;

            if (data.popup != "")
              popup = data.popup;

          }
          this.setState({
          });
        });
      });
  }

  pdfGeneratorService = (canvas, imageData) => {  // TODO: [Debasish] Move this to a service class.
    const pdf = new jsPDF('landscape');
    pdf.addImage(canvas, 'JPEG', 50, 0, 200, 180);
    // pdf.addImage(imageData, 'PNG', 0, 0, 200, 100);
    pdf.save("download.pdf");
  }



  render() {
    let { selectedLeftCordinates, selectedRightCordinates } = this.props;

    return (
      <div className="outer-box" style={{ height: "720px" }}>
        <div className="outer-box-border" style={{ height: "718px" }}>
          <div id="content-chart">
            <ChartJ
              isFromPdfGenerator="true"
              selectedLeftCordinates={selectedLeftCordinates}
              selectedRightCordinates={selectedRightCordinates}
            />
            <span className="tray-toggle-caret-left"></span>
            <span className="tray-toggle-caret-right"></span>
            <div className="audio-output-selector" style={{ top: "17px" }}>
              <div><Avatar style={{ width: "55px", height: "55px", marginRight: '20px', backgroundColor: "#ffffff" }}><img src={left_icon} style={{ width: "54%" }} /></Avatar></div>
              <div><Avatar style={{ width: "55px", height: "55px", backgroundColor: "#ffffff" }}><img src={right_icon} style={{ width: "54%" }} /></Avatar></div>
            </div>

            <div className="audio-main-control2" style={{ left: "21px", marginTop: "-59px", zIndex: 0 }}>
              <div className="audio-output-selector" >
                <Avatar style={{ backgroundColor: "transparent", width: "30px", height: "30px", marginRight: '35px' }}><img src={left_triangle_icon} style={{ width: "54%" }} /></Avatar>
                <Avatar style={{ width: "30px", height: "30px", backgroundColor: "transparent" }}><img src={right_react_icon} style={{ width: "54%" }} /></Avatar>
              </div>
            </div>


            <span className="left-ear-label" style={{ left: "17px", marginTop: "15px", color: "#46b1f5" }}>LEFT EAR</span>
            <span className="right-ear-label" style={{ left: "87px", marginTop: "15px", color: "#ff0000" }}>RIGHT EAR</span>

            <div className="result-box">
              <p>{congrat_msg}</p>
              <Button
                className="download-result"
                variant="contained"
                color="primary"
                onClick={() => {
                  var content = document.getElementsByTagName('canvas')[0]
                  html2canvas(content)
                    .then((canvas) => {
                      const imgData = canvas.toDataURL('image/jpeg', 2.0);
                      this.setState({
                        imageData: imgData
                      })
                      this.pdfGeneratorService(canvas, this.state.imageData)
                    })
                  // OLD CODE

                  // var content = document.getElementsByTagName('canvas')[0]
                  // const imgData = content.toDataURL('image/png');
                  // this.pdfGeneratorService(imgData)
                }}>{download_result}
              </Button>
              <br />
              <p>{want_to_know}</p>

              {popup === "no" ? <Button
                className="consultation-button"
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open(`${consultation_button_url}`, '_blank')
                }}
              >{book_free_consultation}
              </Button>
                :
                <Button
                  className="consultation-button"
                  variant="contained"
                  color="primary"
                  onClick={this.togglePopup.bind(this)}
                >{book_free_consultation}
                </Button>}

            </div>
            <p className="download_heading">{bottom_msg}</p>
          </div>
        </div>
        <div id="content"></div>
        <div className='app'>
          {this.state.showPopup ?
            <Popup
              text='Close Me'
              closePopup={this.togglePopup.bind(this)}
            />
            : null
          }
        </div>
      </div>
    );
  }
}

class Popup extends React.Component {
  render() {
    return (
      <div className='popup'>
        <div className='popup_inner'>
          <iframe src='https://calendly.com/jake-277/free-hearing-consultation?embed_domain=buyaxil.com&embed_type=Inline' width='100%' height='100%'></iframe>
          <button onClick={this.props.closePopup}>close me</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { selectedLeftCordinates: state.charts.selectedLeftCordinates, selectedRightCordinates: state.charts.selectedRightCordinates }
};

export default connect(mapStateToProps)(PdfGenerator);