import React, { Component, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { frequencyData, decibelData } from '../constant/chart';
import _ from 'lodash';
import utils from '../utils';
const { innerWidth: width1, innerHeight: height1 } = window;
var width = width1;
const height = 600;
const numberOfGrids = 12;

const handleResize = e => {
  width = window.innerWidth;
  console.log(width);
};

handleResize();
window.addEventListener("resize", handleResize);

let calculatedValue = () => {
  let diffenceInWidth = width - 120;
  let gridValue = diffenceInWidth / numberOfGrids
  return gridValue
}

let gridValue = calculatedValue();
const options = {
  animation: true,
  responsive: false,
  scales: {
    yAxes: [
      {
        ticks: {
          reverse: true,
          suggestedMax: 120,
          suggestedMin: 0,
          stepSize: 10,
          fontStyle: 'bold'
        },
        gridLines: {
          lineWidth: 1,
          color: 'rgba(255,255,255,0.3)'
        }
      },
    ],
    xAxes: [
      {
        position: 'top',
        gridLines: {
          lineWidth: 1,
          color: ''
        },
        ticks: {
          fontStyle: 'bold'
        }
      },
    ]
  },
  chartArea: {
    normal: {
      backgroundColor: '#E0F2FB'
    },
    mild: {
      backgroundColor: '#FEFCE1'
    },
    moderate: {
      backgroundColor: '#FEE7E0'
    },
    severe: {
      backgroundColor: '#F3D3D6'
    },
    profound: {
      backgroundColor: '#C1A4A5'
    }
  }
}



const plugins = [{
  afterDraw: (chartInstance, easing) => {
    const ctx = chartInstance.chart.ctx;
    ctx.font = "17px Arial"
    ctx.textAlign = "start";
    ctx.fillStyle = "#ffffff";
    ctx.fillText("Normal", 40, 98);
  }
},
{
  afterDraw: (chartInstance, easing) => {
    const ctx = chartInstance.chart.ctx;
    ctx.fillText("Mild", 40, 167);
  }
},
{
  afterDraw: (chartInstance, easing) => {
    const ctx = chartInstance.chart.ctx;
    ctx.fillText("Moderate", 40, 250);
  }
},
{
  afterDraw: (chartInstance, easing) => {
    const ctx = chartInstance.chart.ctx;
    ctx.fillText("Severe", 40, 337);
  }
},
{
  afterDraw: (chartInstance, easing) => {
    const ctx = chartInstance.chart.ctx;
    ctx.textAlign = "start";
    ctx.fillText("Profound", 40, 425);
  }
},
{
  beforeDraw: (chart, easing) => {
   

    if (chart.options.chartArea && chart.options.chartArea.normal.backgroundColor) {
      var ctx = chart.chart.ctx;
      var chartArea = chart.chartArea;

      var grd = ctx.createLinearGradient(0, 0, 0, 480);
      grd.addColorStop(0, '#84c9fd');
      grd.addColorStop(0.5, '#faae6f');
               
      grd.addColorStop(0.5, '#faae6f');
      grd.addColorStop(1, '#e47d71');

      ctx.save();
      ctx.fillStyle = grd;
      ctx.fillRect(0, 0, width,height);
      ctx.restore();
    }
  }
},
{
  beforeDraw: (chart, easing) => {
    if (chart.options.chartArea && chart.options.chartArea.mild.backgroundColor) {
      var ctx = chart.chart.ctx;
      var chartArea = chart.chartArea;
      ctx.save();
      // ctx.fillStyle = chart.options.chartArea.mild.backgroundColor;     
      // ctx.fillRect(chartArea.left, chartArea.top + gridValue * 2, chartArea.right - chartArea.left, gridValue * 2);
      // ctx.restore();
    }
  }
},
{
  beforeDraw: (chart, easing) => {
    if (chart.options.chartArea && chart.options.chartArea.moderate.backgroundColor) {
      var ctx = chart.chart.ctx;
      var chartArea = chart.chartArea;
      ctx.save();
      // ctx.fillStyle = chart.options.chartArea.moderate.backgroundColor;
      // ctx.fillRect(chartArea.left, chartArea.top + gridValue * 4, chartArea.right - chartArea.left, gridValue * 2);
      // ctx.restore();
    }
  }
},
{
  beforeDraw: (chart, easing) => {
    if (chart.options.chartArea && chart.options.chartArea.severe.backgroundColor) {
      var ctx = chart.chart.ctx;
      var chartArea = chart.chartArea;
      ctx.save();
      // ctx.fillStyle = chart.options.chartArea.severe.backgroundColor;
      // ctx.fillRect(chartArea.left, chartArea.top + gridValue * 6, chartArea.right - chartArea.left, gridValue * 3);
      // ctx.restore();
    }
  }
},
{
  beforeDraw: (chart, easing) => {
    if (chart.options.chartArea && chart.options.chartArea.profound.backgroundColor) {
      var ctx = chart.chart.ctx;
      var chartArea = chart.chartArea;
      ctx.save();
      // ctx.fillStyle = chart.options.chartArea.profound.backgroundColor;
      // ctx.fillRect(chartArea.left, chartArea.top + gridValue * 9, chartArea.right - chartArea.left, gridValue * 3);
      // ctx.restore();
    }
  }
}
];

class ChartJ extends Component {

  calculateWidth = () => {
    return (width)
  }

  calculateHeight = () => {
    return (height - 120)
  }

  render() {
    let { selectedLeftCordinates, selectedRightCordinates, activeCordinate, isFromPdfGenerator } = this.props;

    let datasets = [];

    if (!_.isEmpty(activeCordinate)) {
      const data = [];
      data.push(activeCordinate)
      datasets.push(utils.getChartObject(data, "Active"))
    }

    if (!_.isEmpty(selectedLeftCordinates)) {
      const data = [];
      datasets.push(utils.getChartObject(selectedLeftCordinates, "Left"))
    }

    if (!_.isEmpty(selectedRightCordinates)) {
      datasets.push(utils.getChartObject(selectedRightCordinates, "Right"))
    }

    let result = {
      labels: frequencyData.map((i) => i),
      datasets: datasets
    }
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <Line data={result} options={options} plugins={plugins} width={this.calculateWidth()} height={this.calculateHeight()} />
      </div>
    );
  }
};

export default ChartJ;