import React from 'react';
import { Provider } from "react-redux";
import store from "./store";
import HearingTest from './views/hearing-test/hearing-test';
import PdfGenerator from './views/pdf-generator/pdf-generator';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";


function App() {
  return (
    <Provider store={store}>
      <Router>
        {/* <HearingTest /> */}
        <Switch>
          <Route exact path="/pdf" component={PdfGenerator} />
          <Route path="/" component={HearingTest} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
