import { SELECTED_LEFT_CORDINATES, SELECTED_RIGHT_CORDINATES } from "./types";

export const selectedLeftCordinates = (data) => dispatch => {
  dispatch({
    type: SELECTED_LEFT_CORDINATES, // from here it goes to reducer which will check for a matching case in switch case statement
    payload: data
  })
};
export const selectedRightCordinates = (data) => dispatch => {
  dispatch({
    type: SELECTED_RIGHT_CORDINATES,
    payload: data
  })
}