import { SELECTED_LEFT_CORDINATES, SELECTED_RIGHT_CORDINATES } from "../actions/types";

const initialState = {
  selectedLeftCordinates: [],
  selectedRightCordinates: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECTED_LEFT_CORDINATES:
      return {
        ...state,
        selectedLeftCordinates: action.payload,
      };
    case SELECTED_RIGHT_CORDINATES:
      return {
        ...state,
        selectedRightCordinates: action.payload,
      }
    default:
      return state;
  }
}